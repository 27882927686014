import "../css/Header.css";
import CTAButton from "./CTAButton";
import useScrollPosition from "../hooks/useScrollPosition";
import { useRef } from "react";
import { useEffect } from "react";

function Header({ className, CTAHandler }) {
    const scrollPosition = useScrollPosition();
    const headerRef = useRef(null);

    useEffect(() => {
        if (scrollPosition <= 765) {
            headerRef.current.style.backgroundColor = `rgba(30, 49, 96, ${
                scrollPosition / 765
            })`;
        } else {
            headerRef.current.style.backgroundColor = `rgba(30, 49, 96, 1)`;
        }
    }, [scrollPosition]);

    return (
        <header className={className} ref={headerRef}>
            <img
                className="header-logo"
                src="/keel-mind-logo.webp"
                alt="Keel Mind logo"
            />
            <CTAButton short={true} onClick={CTAHandler}>
                Start your demo
            </CTAButton>
        </header>
    );
}

export default Header;
