export const faqData = [
    {
        title: "What is Keel Mind?",
        description:
            "Keel Mind is a digital platform that provides mental health support to students at colleges and universities across Ontario at no cost. Delivered through secure text, audio, and video sessions, all appointments are confidential, easily accessible and offered online.",
    },
    {
        title: "Why do I have to use my university/college email address to register?",
        description:
            "We require your school email because this Keel Mind program is being offered to Ontario postsecondary students only. Your school email is used for signup purposes only. Any sessions or notes taken on the platform <strong>will not</strong> be shared with your school.",
    },
    {
        title: "Do I need to download an app?",
        description:
            "No, you do not need to download an app to access this service.",
    },
    {
        title: "Does it cost anything to register?",
        description:
            "No. This service is offered at <strong>no cost</strong> to all students enrolled at an Ontario college or university.",
    },
];
